import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private themeState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public themeStateObs = this.themeState.asObservable();

    constructor() {}

    public changeTheme(state: boolean): void {
        this.themeState.next(state);
        if (state) {
          document.body.classList.add('dark-theme');
          document.body.classList.remove('light-theme');
        } else {
          document.body.classList.add('light-theme');
          document.body.classList.remove('dark-theme');
        }
    }
}
