 import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from '@app/Shared/interfaces/iMenu';
 
@Component({
    selector: 'app-left-nav',
    templateUrl: './left-nav.component.html',
    styleUrls: ['./left-nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LeftNavComponent implements OnInit {
    @Input() mini: boolean = false;

    constructor() {}

    ngOnInit(): void {}

    menuItems: MenuItem[] = [
        {
            title: 'Data Explorer',
            page: '/data-explorer',
            iconClass: 'database',
        },
        { title: 'Reports', page: '/reports', iconClass: 'lab_profile' },

        { title: 'Settings', page: '/settings', iconClass: 'settings' },
    ];

    menuItemsFooter: MenuItem[] = [
        { title: 'Log out', page: '/logout', iconClass: 'logout' },
    ];

    userLogo: string = '/assets/images/user-logo.png';
}
