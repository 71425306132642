import { createReducer, on } from '@ngrx/store';
import * as DashboardActions from '@app/Store/actions/dashboard-actions';
import { iDashboardState } from '@app/Shared/interfaces/iDashboard';

export const initialDashboardState: iDashboardState = {
    queryParams: {},
    data: {
        entities: [],
        selectedEntity: null,
        masterTables: [],
        selectedTab: 'Parent',
        selectedParentGridData: null,
        selectedChildGridData: null,
        selectedMatchGridData: null,
        selectedMergeGridData: null,
    },
    error: null,
};

export const dashboardReducer = createReducer(
    initialDashboardState,
    on(DashboardActions.changeSelectedTab, (state, { selectedTab })=>({
        ...state,
        data: {
            ...state.data,
            selectedTab: selectedTab,
        }
    })),
    on(DashboardActions.loadDataEntitySuccess, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            entities: data,
        },
        error: null,
    })),
    on(DashboardActions.loadDataMasterTablesSuccess, (state, { masterTables }) => ({
        ...state,
        data: {
            ...state.data,
            masterTables,
        },
        error: null,
    })),
    on(DashboardActions.selectedEntity, (state, { selectedEntity }) => ({
        ...state,
        data: {
            ...state.data,
            selectedEntity: {
                ...selectedEntity,
                selectedRecords: selectedEntity.selectedRecords?.map(record => ({
                    ...record,
                    details: {
                        parentData: [],
                        childData: [],
                        matchData: [],
                        mergeData: [],
                    },
                })) || null,
            },
        },
        error: null,
    })),
    on(DashboardActions.loadSelectedEntityRowDataSuccess, (state, { rowData }) => {
        if (state.data.selectedEntity) {
            return {
                ...state,
                data: {
                    ...state.data,
                    selectedEntity: {
                        ...state.data.selectedEntity,
                        rowData: rowData ?? [],
                    },
                },
                error: null,
            };
        }
        return state;
    }),
    on(DashboardActions.storeSelectedRecords, DashboardActions.setSelectedRecords, (state, { selectedRecords }) => {
        if (state.data.selectedEntity) {
            return {
                ...state,
                data: {
                    ...state.data,
                    selectedEntity: {
                        ...state.data.selectedEntity,
                        selectedRecords: selectedRecords.map(record => ({
                            data: record,
                            details: {
                                parentData: [],
                                childData: [],
                                matchData: [],
                                mergeData: [],
                            },
                        })),
                        isSingleRecord: selectedRecords.length === 1,
                    },
                },
                error: null,
            };
        }
        return state;
    }),
    on(DashboardActions.changeParentGridSelectedData, (state, { selectedRow }) => ({
        ...state, 
        data: {
            ...state.data,
            selectedParentGridData: selectedRow
        }
    })),
    on(DashboardActions.changeChildGridSelectedData, (state, { selectedRow }) => ({
        ...state, 
        data: {
            ...state.data,
            selectedChildGridData: selectedRow
        }
    })),
    on(DashboardActions.changeMatchGridSelectedData, (state, { selectedRow }) => ({
        ...state, 
        data: {
            ...state.data,
            selectedMatchGridData: selectedRow
        }
    })),
    on(DashboardActions.changeMergeGridSelectedData, (state, { selectedRow }) => ({
        ...state, 
        data: {
            ...state.data,
            selectedMergeGridData: selectedRow
        }
    })),
    on(DashboardActions.fetchParentDataSuccess, (state, { parentData }) => updateNestedDetails(state, 'parentData', parentData)),
    on(DashboardActions.fetchChildDataSuccess, (state, { childData }) => updateNestedDetails(state, 'childData', childData)),
    on(DashboardActions.fetchMergeDataSuccess, (state, { mergeData }) => updateNestedDetails(state, 'mergeData', mergeData)),
    on(DashboardActions.fetchMatchDataSuccess, (state, { matchData }) => updateNestedDetails(state, 'matchData', matchData)),
    on(
        DashboardActions.fetchParentDataFailure,
        DashboardActions.fetchChildDataFailure,
        DashboardActions.fetchMergeDataFailure,
        DashboardActions.fetchMatchDataFailure,
        (state, { error }) => ({
            ...state,
            error,
        })
    )
);

const updateNestedDetails = (state: iDashboardState, key: string, data: any[]) => {
    if (state.data.selectedEntity && state.data.selectedEntity.selectedRecords) {
        const updatedRecords = state.data.selectedEntity.selectedRecords.map(record => ({
            ...record,
            details: {
                ...record.details,
                [key]: data ?? [],
            },
        }));

        return {
            ...state,
            data: {
                ...state.data,
                selectedEntity: {
                    ...state.data.selectedEntity,
                    selectedRecords: updatedRecords,
                },
            },
            error: null,
        };
    }
    return state;
};
