import { NgModule, ModuleWithProviders } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';

// Set the license for AG-Grid Enterprise
import { LicenseManager } from 'ag-grid-enterprise';
import { CommonModule } from '@angular/common';
// LicenseManager.setLicenseKey(
//     '[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-061119}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{31 July 2024}____[v3]_[0102]_MTcyMjM4MDQwMDAwMA==945278da60ff465f559911e628a1a59f'
// );

@NgModule({
    declarations: [],
    imports: [CommonModule, AgGridModule],
    exports: [AgGridModule],
})
export class GridModule {}
