import { LoggerService } from './../../Services/logger.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as DashboardActions from '../actions/dashboard-actions';
import * as UiActions from '../actions/ui-actions';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { OdataService } from '@app/Services/o-data.service';
import { iParsedEntity } from '@app/Shared/interfaces/iEntityParsed';
import { extractChildTable, extractParentTable, extractMatchTable, extractMergeTable, extractParts, pluralize } from '@app/Shared/util/stringManipulation';
import { iEntityRelationship } from '@app/Shared/interfaces/iEntityRelationship';
import { iRelationshipRequest } from '@app/Shared/interfaces/iRelationshipRequest';
import { AddParentChildIn } from '@app/Shared/models/add-parent-in.model';
import { AlertService, AlertType } from '@app/Services/Utilities/alert.service';
import { RemoveParentChildIn } from '@app/Shared/models/remove-parent-in.model';
import { ChangeParentChildLinkKey } from '@app/Shared/models/change-parent-child-link-key.model';
import { ReassignMatch } from '@app/Shared/models/reassign-match.model';
import { OverrideHistoryIn } from '@app/Shared/models/override-history-in.model';

@Injectable()
export class DashboardEffects {
    public cn!: string;

    constructor(
        private actions$: Actions,
        private odataService: OdataService,
        private store: Store<AppState>,
        private con: LoggerService,
        private alertService: AlertService
    ) {}

    loadEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadDataEntities),
            switchMap(({ query }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getMetaData(query).pipe(
                    map((data) => DashboardActions.loadDataEntitySuccess({ data })),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.loadDataEntityFailure({ error }));
                    })
                );
            })
        )
    );

    loadMasterTables$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadDataMasterTables),
            switchMap(({ query }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getOdata(query).pipe(
                    map((masterTables: any) => {
                        const data: any = masterTables?.value;
                        return DashboardActions.loadDataMasterTablesSuccess({ masterTables: data })
                    } ),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.loadDataMasterTablesFailure({ error }));
                    })
                );
            })
        )
    );

    loadSelectedEntityRowData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadSelectedEntityRowData),
            switchMap(({ entityName, pageIndex, pageSize }) => {
                this.store.dispatch(UiActions.startLoading());
                const option = pageIndex != null? {count: true, skip: pageIndex * pageSize, top: pageSize }: {};
                return this.odataService.getOdata(entityName, option).pipe(
                    map((rowData) => DashboardActions.loadSelectedEntityRowDataSuccess({ rowData })),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        console.error('Load Selected Entity Row Data Error:', error);
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.loadSelectedEntityRowDataFailure({ error }));
                    })
                );
            })
        )
    );

    fetchParentData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.fetchParentData),
            switchMap(({ masterTable, masterKey }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getParentData(masterTable, masterKey).pipe(
                    map((parentData) => {
                        console.log('Parent data fetched:', parentData); // Debugging statement
                        return DashboardActions.fetchParentDataSuccess({ parentData });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        console.error('Fetch Parent Data Error:', error);
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.fetchParentDataFailure({ error }));
                    })
                );
            })
        )
    );

    fetchChildData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.fetchChildData),
            switchMap(({ masterTable, masterKey }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getChildData(masterTable, masterKey).pipe(
                    map((childData) => {
                        console.log('Child data fetched:', childData); // Debugging statement
                        return DashboardActions.fetchChildDataSuccess({ childData });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        console.error('Fetch Child Data Error:', error);
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.fetchChildDataFailure({ error }));
                    })
                );
            })
        )
    );

    fetchMatchData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.fetchMatchData),
            switchMap(({ masterTable, masterKey }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getMatchData(masterTable, masterKey).pipe(
                    map((matchData) => {
                        console.log('Match data fetched:', matchData); //Debugging statement
                        return DashboardActions.fetchMatchDataSuccess({ matchData });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        console.error('Fetch Match Data Error:', error);
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.fetchMatchDataFailure({ error }));
                    })
                );
            })
        )
    );

    fetchMergeData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.fetchMergeData),
            switchMap(({ masterTable, masterKey }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getMergeData(masterTable, masterKey).pipe(
                    map((mergeData) => {
                        console.log('Merge data fetched:', mergeData); //Debugging statement
                        return DashboardActions.fetchMergeDataSuccess({ mergeData });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        console.error('Fetch Merge Data Error:', error);
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.fetchMergeDataFailure({ error }));
                    })
                );
            })
        )
    );

    addParentChild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.addParentChildData),
            switchMap((data: AddParentChildIn) => {
                const sendModel: AddParentChildIn = {
                    linkTable: data.linkTable,
                    masterKey: data.masterKey,
                    masterTable: data.masterTable,
                    newRelationKey: data.newRelationKey,
                    relationship: data.relationship,
                };

                this.store.dispatch(UiActions.startLoading());
                return this.odataService.addParentChild(sendModel).pipe(
                    map((res) => {
                        if (data.relationship.toLocaleLowerCase() === 'parent')
                            this.store.dispatch(DashboardActions.fetchParentData({ masterTable: data.masterTable.toString(), masterKey: data.masterKey.toString() }));
                        else this.store.dispatch(DashboardActions.fetchChildData({ masterTable: data.masterTable.toString(), masterKey: data.masterKey.toString() }));

                        return DashboardActions.addParentChildDataSuccess({
                            masterKey: data.masterKey.toString(),
                            masterTable: data.masterTable.toString(),
                            relationship: data.relationship,
                        });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.addParentChildDataFailure({ masterKey: data.masterKey, relationship: data.relationship }));
                    })
                );
            })
        )
    );

    addParentChildSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.addParentChildDataSuccess),
                tap(({ masterKey, relationship }) => {
                    if (relationship.toLocaleLowerCase() === 'parent')
                        this.alertService.alert(AlertType.Success, `Parent ${masterKey} successfully linked to the following Child record: *****`, '');
                    if (relationship.toLocaleLowerCase() === 'child')
                        this.alertService.alert(AlertType.Success, `Child ${masterKey} successfully linked to the following Parent record: *****`, '');
                })
            ),
        { dispatch: false }
    );

    addParentChildFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.addParentChildDataFailure),
                tap(({ masterKey, relationship }) => {
                    if (relationship.toLocaleLowerCase() === 'parent')
                        this.alertService.alert(AlertType.Error, `Parent ${masterKey} Not Linked:Error Occurred: Please Check Master Key Value`, '');
                    if (relationship.toLocaleLowerCase() === 'child')
                        this.alertService.alert(AlertType.Error, `Child ${masterKey} Not Linked:Error Occurred: Please Check Master Key Value`, '');
                })
            ),
        { dispatch: false }
    );

    removeParentChild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.removeParentChildData),
            switchMap((data: RemoveParentChildIn) => {
                const sendModel: RemoveParentChildIn = {
                    linkTable: data.linkTable,
                    masterKey: data.masterKey,
                    masterTable: data.masterTable,
                    linkKey: data.linkKey,
                    relationship: data.relationship,
                };

                this.store.dispatch(UiActions.startLoading());
                return this.odataService.removeParentChild(sendModel).pipe(
                    map((res) => {
                        if (data.relationship.toLocaleLowerCase() === 'parent') {
                            this.store.dispatch(DashboardActions.changeParentGridSelectedData({ selectedRow: null }));
                            this.store.dispatch(DashboardActions.fetchParentData({ masterTable: data.masterTable.toString(), masterKey: data.masterKey.toString() }));
                        } else {
                            this.store.dispatch(DashboardActions.changeChildGridSelectedData({ selectedRow: null }));
                            this.store.dispatch(DashboardActions.fetchChildData({ masterTable: data.masterTable.toString(), masterKey: data.masterKey.toString() }));
                        }
                        return DashboardActions.removeParentChildDataSuccess({
                            masterKey: data.masterKey.toString(),
                            masterTable: data.masterTable.toString(),
                            relationship: data.relationship,
                        });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.removeParentChildDataFailure({ masterKey: data.masterKey, relationship: data.relationship }));
                    })
                );
            })
        )
    );

    removeParentChildSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.removeParentChildDataSuccess),
                tap(({ masterKey, relationship }) => {
                    if (relationship.toLocaleLowerCase() === 'parent') {
                        this.alertService.alert(AlertType.Success, `Parent ${masterKey} successfully linked to the following Child record: *****`, '');
                    } else {
                        this.alertService.alert(AlertType.Success, `Child ${masterKey} successfully linked to the following Parent record: *****`, '');
                    }
                })
            ),
        { dispatch: false }
    );

    removeParentChildFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.removeParentChildDataFailure),
                tap(({ masterKey, relationship }) => {
                    if (relationship.toLocaleLowerCase() === 'parent') {
                        this.alertService.alert(AlertType.Error, `Parent Master Key not found. `, '');
                    } else {
                        this.alertService.alert(AlertType.Error, `Child Master Key not found. `, '');
                    }
                })
            ),
        { dispatch: false }
    );

    changeParentChild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.changeParentChildData),
            switchMap((data: ChangeParentChildLinkKey) => {
                const sendModel: ChangeParentChildLinkKey = {
                    linkTable: data.linkTable,
                    masterKey: data.masterKey,
                    masterTable: data.masterTable,
                    linkKey: data.linkKey,
                    newMasterKey: data.newMasterKey,
                    relationship: data.relationship,
                };

                this.store.dispatch(UiActions.startLoading());
                return this.odataService.changeParentChildLinkKey(sendModel).pipe(
                    map((res) => {
                        this.store.dispatch(DashboardActions.fetchParentData({ masterTable: data.masterTable.toString(), masterKey: data.masterKey.toString() }));
                        return DashboardActions.changeParentChildDataSuccess({
                            masterKey: data.masterKey.toString(),
                            masterTable: data.masterTable.toString(),
                            newMasterkey: data.newMasterKey.toString(),
                        });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.changeParentChildDataFailure({ masterKey: data.masterKey }));
                    })
                );
            })
        )
    );

    changeParentChildSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.changeParentChildDataSuccess),
                tap(({ masterKey, newMasterkey }) => {
                    this.alertService.alert(AlertType.Success, `Link Successfully reassigned from (old) Parent ${masterKey}  to (new) Parent ${newMasterkey}:`, '');
                })
            ),
        { dispatch: false }
    );

    changeParentChildFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.changeParentChildDataFailure),
                tap(({ masterKey }) => {
                    this.alertService.alert(AlertType.Error, `Parent ${masterKey} Not Linked:Error Occurred: Please Check Master Key Value`, '');
                })
            ),
        { dispatch: false }
    );

    reassignMatch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.reassignMatch),
            switchMap((data: ReassignMatch) => {
                const sendModel: ReassignMatch = {
                    datasetName: data.datasetName,
                    masterKey: data.masterKey,
                    masterTable: data.masterTable,
                    matchKey: data.matchKey,
                    newMasterKey: data.newMasterKey,
                };

                this.store.dispatch(UiActions.startLoading());
                return this.odataService.reassignMatch(sendModel).pipe(
                    map((res) => {
                        this.store.dispatch(DashboardActions.changeChildGridSelectedData({ selectedRow: null }));
                        this.store.dispatch(DashboardActions.fetchChildData({ masterTable: data.masterTable.toString(), masterKey: data.masterKey.toString() }));

                        return DashboardActions.reassignMatchSuccess({
                            masterKey: data.masterKey.toString(),
                            masterTable: data.masterTable.toString(),
                            newMasterkey: data.newMasterKey.toString(),
                        });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.reassignMatchFailure({ masterKey: data.masterKey }));
                    })
                );
            })
        )
    );

    reassignMatchSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.removeParentChildDataSuccess),
                tap(({ masterKey, relationship }) => {
                    if (relationship.toLocaleLowerCase() === 'parent') {
                        this.alertService.alert(AlertType.Success, `Parent ${masterKey} successfully linked to the following Child record: *****`, '');
                    } else {
                        this.alertService.alert(AlertType.Success, `Child ${masterKey} successfully linked to the following Parent record: *****`, '');
                    }
                })
            ),
        { dispatch: false }
    );

    reassignMatchFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.removeParentChildDataFailure),
                tap(({ masterKey, relationship }) => {
                    if (relationship.toLocaleLowerCase() === 'parent') {
                        this.alertService.alert(AlertType.Error, `Parent Master Key not found. `, '');
                    } else {
                        this.alertService.alert(AlertType.Error, `Child Master Key not found. `, '');
                    }
                })
            ),
        { dispatch: false }
    );    

    overrideHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.overrideHistory),
            switchMap((data: OverrideHistoryIn) => {
                const sendModel: OverrideHistoryIn = {
                    masterKey: data.masterKey,
                    masterTable: data.masterTable,
                    historyKey: data.historyKey,
                    newFieldValue: data.newFieldValue
                };

                this.store.dispatch(UiActions.startLoading());
                return this.odataService.overrideHistory(sendModel).pipe(
                    map((res) => {
                        this.store.dispatch(DashboardActions.changeMergeGridSelectedData({ selectedRow: null }));
                        this.store.dispatch(DashboardActions.fetchMergeData({ masterTable: data.masterTable.toString(), masterKey: data.masterKey.toString() }));

                        return DashboardActions.overrideHistorySuccess({newFieldValue: data.newFieldValue.toString()});
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.overrideHistoryFailure());
                    })
                );
            })
        )
    );

    overrideHistorySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.overrideHistorySuccess),
                tap(({newFieldValue}) => {
                        this.alertService.alert(AlertType.Success, `Parent Master Key changed to: ${newFieldValue} `, '');
                })
            ),
        { dispatch: false }
    );

    overrideHistoryFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.overrideHistoryFailure),
                tap(() => {
                        this.alertService.alert(AlertType.Error, `Master Key does not exist, please verify and try again `, '');
                })
            ),
        { dispatch: false }
    );



    private getNavigationPropertiesWithLinks(entity: iParsedEntity): string[] {
        return entity.navigationProperties.filter((prop) => prop.name.endsWith('Links')).map((prop) => prop.name);
    }

    private determineParentChildLinks(entityName: string, navProps: string[]): iEntityRelationship {
        const parentLinksData: string[] = [];
        const childLinksData: string[] = [];

        navProps.forEach((navProp) => {
            const parts = extractParts(navProp);
            if (parts.length < 2) {
                return;
            }

            const closestPartToLinks = parts[parts.length - 1];

            if (entityName.toLowerCase() === closestPartToLinks.toLowerCase()) {
                parentLinksData.push(navProp);
            } else {
                childLinksData.push(navProp);
            }
        });

        return { parentLinksData, childLinksData };
    }

    private formulateODataRequest(
        entityName: string,
        selectedRecords: any[],
        parentChildRelationship: iEntityRelationship,
        isSingleRowSelected: boolean
    ): iRelationshipRequest {
        const entityKey = `${entityName}Trukey`;
        const parentTable = pluralize(entityName);
        const { parentLinksData, childLinksData } = parentChildRelationship;

        const createRequest = (table: string, expandPart: string) => {
            if (isSingleRowSelected) {
                const [rowOne] = selectedRecords;
                return `${table}(${rowOne[entityKey]})?$expand=${expandPart}`;
            } else {
                const filters = selectedRecords.map((row) => `${entityKey} eq ${row[entityKey]}`).join(' or ');
                return `${table}?$filter=${filters}&$expand=${expandPart}`;
            }
        };

        const parentRequests = parentLinksData.map((link) => {
            return {
                link,
                sublink: extractParentTable(entityName, link),
                linkRequest: createRequest(parentTable, `${link}($expand=${extractParentTable(entityName, link)})`),
            };
        });

        const childRequests = childLinksData.map((link) => {
            return {
                link,
                sublink: extractChildTable(entityName, link),
                linkRequest: createRequest(parentTable, `${link}($expand=${extractChildTable(entityName, link)})`),
            };
        });

        /* const matchRequests = matchData.map((link) => {
            return { 
                link,
                sublink: extractMatchTable(entityName, link),
                linkRequest: createRequest(parentTable, `${link}($expand=${extractMatchTable(entityName, link)})`),
            };
        })

        const mergeRequests = mergeData.map((link) => {
            return {
                link,
                sublink: extractMergeTable(entityName, link),
                linkRequest: createRequest(parentTable, `${link}($expand=${extractMergeTable(entityName, link)})`),
            };
        })
 */

        return { parentRequests, childRequests };
    }
}
