import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import { AlertComponent } from './alert/alert.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogActions as MatDialogActions, MatLegacyDialogClose as MatDialogClose, MatLegacyDialogContent as MatDialogContent, MatLegacyDialogModule as MatDialogModule, MatLegacyDialogTitle as MatDialogTitle } from '@angular/material/legacy-dialog';



@NgModule({
  declarations: [
    ConfirmComponent,
    AlertComponent,
    // MatDialogActions, 
    // MatDialogClose, 
    // MatDialogTitle, 
    // MatDialogContent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [ConfirmComponent, AlertComponent]
})
export class AlertModule { }
