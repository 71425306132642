<!-- Creates a layout with a left-positioned sidenav and explicit content. -->
<!--<app-oauth (onOauthLoad)="loadApp()" spinner></app-oauth>-->
<app-oauth (onOauthLoad)="loadApp()" rootContext="/" [serverless]="true" [serverlessConfig]="oauthConfig"></app-oauth>

<div class="w-100 h-100 parent-container overflow-hidden" style="max-width: 100%">
    <div [@openClose]="isMini ? 'mini' : 'open'" class="transition-on-width sidenav h-100">
        <div class="m-0 p-0 w-100 h-100">
            <div class="p-0 d-flex flex-column w-100 h-100" [ngClass]="{ 'sidebar-container': isMini === false }">
                <div style="flex: 1">
                    <app-left-nav [mini]="isMini"></app-left-nav>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="w-100 h-100 td-content-container overflow-auto">
            <app-header [mini]="isMini" (toggleMiniToParent)="toggleMini()"></app-header>
            <div class="w-100 h-100 loader-parent">
                <router-outlet></router-outlet>
                <div class="loader-second-child" [ngClass]="{ overlay: isLoading }">
                    <div class="loader"></div>
                </div>
            </div>
        </div>
    </div>
</div>

