import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AlertType } from '@app/Services/Utilities/alert.service';

export interface AlertDialogData {
    title: string;
    message: string;
    oKText: string;
    type: AlertType;
}

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
    statusClass: string = '';

    constructor(public dialogRef: MatDialogRef<AlertComponent>, @Inject(MAT_DIALOG_DATA) public data: AlertDialogData) {
      this.setStatus(data.type);
    }

    ngOnInit(): void {}

    public setStatus(type: AlertType): void {
        switch (type) {
            case AlertType.Error:
                this.statusClass = 'error-class';
                break;
            case AlertType.Success:
                this.statusClass = 'success-class';
                break;
            case AlertType.Info:
                this.statusClass = 'info-class';
                break;
            case AlertType.Warning:
                this.statusClass = 'warning-class';
                break;
            default:
                this.statusClass = 'success-class';
                break;
        }
    }
}
